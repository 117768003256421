/* ------------------------------------------------------------------------------
    Template Name: SamBayley
    Description: SamBayley - Personal CV/Resume HTML Template
    Author: Paul
    Author URI: http://themeforest.net/user/Paul_tf
    1. Global
        1.1 Libs
        1.2 Fonts
        1.3 Sprite
        1.4 Typography
        1.5 Btns
        1.6 Loader
            1.6.1 Loader Animation
    2. Header
        2.1 Header - Logo
        2.2 Header - Contacts
        2.3 Header - Languages
        2.4 Header - Burger
        2.5 Header - Navbar
    3. Social
    4. Main
        4.1 Main - Sections' scroll
        4.2 Main - Sections general
        4.3 Main - Hero section. First section
            4.3.1 Main - Styles for magnific video
        4.4 Main - Experience section. Second section
        4.5 Main - Education section. Third section
        4.6 Main - Projects section. Fourth section
        4.7 Main - Services section. Fifth section
        4.8 Main - Reviews section. Sixth section
        4.9 Main - Latest news section. Seventh section
        4.10 Main - Contact section. Eight section
    5.Footer
        5.1 Footer - Button to top
    6. Modal
    7. Ticker
    8. Project
        8.1 Project - Project info
        8.2 Project - Project section
    9. Blog
    10. Comment */

/* 1. Global */

/* 1.1 Libs */

@import "bootstrap-grid.min.css";
@import "jquery.pagepiling.min.css";
@import "swiper-bundle.min.css";
@import "magnific-popup.css";

@import "./vars";
@import "./mixins";

/* 1.2 Fonts */
@import "./fonts";
/* 1.3 Sprite */
@import "./sprite_generated";


* {
    box-sizing: border-box; }

html, body, .main, .section {
    overflow-x: hidden; }

html.inner-page, body.inner-page, body.inner-page .main {
    overflow-y: auto !important; }

body {
    display: flex;
    flex-direction: column;
    background: $dark-color;
    font-family: $nm-regular, "Helvetica", Arial, sans-serif;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/* 1.4 Typography */

h1, .h1 {
    font-family: $nm-regular;
    font-weight: normal;
    font-size: 140px;
    line-height: 90%;
    color: $main-color;
    margin: 0; }

h2, .h2 {
    font-family: $nm-regular;
    font-weight: normal;
    font-size: 60px;
    line-height: 100%;
    color: $main-color;
    margin: 0; }

p {
    margin: 0; }

a {
    text-decoration: none;
    transition: $transition; }

.container {
    padding: 0 15px; }

[class^=svg-image-] {
    display: inline-block;
    vertical-align: middle; }

/* 1.5 Btns */

.btn {
    display: block;
    background: $mark-color;
    padding: 17px 42px;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $main-color;
    transition: $transition;
    text-decoration: none;
    &:hover, &:focus, {
        color: $mark-color;
        background: $main-color; }
    &:focus {
        outline: none; }
    &-border {
        background: $dark-color;
        border: 1px solid $main-color;
        &:hover, &:focus {
            color: $dark-color;
            background: $main-color; } } }

/* 1.6 Loader */

.loader {
    position: fixed;
    overflow: hidden;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $dark-color;
    color: $black-color;
    text-align: center; }

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $main-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

/* 1.6.1 Loader Animation */

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0); }
    50% {
        -webkit-transform: scale(1.0); } }

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0); }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0); } }
