/* 8. Project */
/* 8.1 Project - Project info */
.project-info {
    padding: 85px 0;
    border-bottom: 1px solid $main-color; }

.pi {
    &-items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
    &-item {
        &__title {
            font-family: $g-semibold;
            font-size: 16px;
            line-height: 150%;
            color: $main-color;
            margin: 0 0 6px; }
        &__txt {
            font-family: $g-regular;
            font-size: 16px;
            line-height: 150%;
            color: $main-color; } } }

/* 8.2 Project - Project section */

.project-section {
    padding: 180px 0 40px;
    &:last-child {
        padding: 180px 0 220px; } }

.ps {
    &-title {
        margin-bottom: 85px;
        font-family: $nm-medium;
        text-align: center; }
    &-txt {
        text-align: center;
        font-size: 15px;
        line-height: 150%;
        color: $main-color;
        padding: 0 60px;
        margin-bottom: 150px;
        a {
            font-size: 15px;
            line-height: 150%;
            color: $mark-color; } }
    &-img {
        text-align: center;
        margin-bottom: 170px;
        position: relative;
        img {
            max-width: 100%; }
        &:last-child {
            margin-bottom: 0; }
        &-mask {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 100%;
            transition-property: transform;
            transform: translate3d(-100%,0,0);
            transition-duration: 1s;
            transition-timing-function: ease;
            .inside {
                transform: skewX(-10deg);
                background: $dark-color;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transform-origin: bottom; } }
        &.active {
            .ps-img-mask {
                transform: translateZ(0); } } }

    &-video {
        height: 760px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../images/video-bg.jpg);
        background-repeat: no-repeat;
        background-position: center; }
    &-heart {
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 119px;
            height: 119px;
            border-radius: 50%;
            background: $mark-color;
            margin: auto;
            svg {
                path {
                    transition: $transition; } }
            &:hover {
                background: $main-color;
                svg {
                    path {
                        fill: $mark-color; } } } } } }
