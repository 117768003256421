// Grid
$width_xl: 1440px;
$width_lg: 1340px;
$width_tablet: 1024px;
$width_md: 991px;
$width_xs: 767px;
$width_mob: 600px;

// fonts
$g-bold: "Gilroy-Bold", sans-serif;
$g-semibold: "Gilroy-SemiBold", sans-serif;
$g-medium: "Gilroy-Medium", sans-serif;
$g-regular: "Gilroy-Regular", sans-serif;
$nm-regular: "NeueMontreal-Regular", sans-serif;
$nm-medium: "NeueMontreal-Medium", sans-serif;
$sf-medium: "SFProDisplay-Medium", sans-serif;

// colors
$main-color: #FDE3A7;
$mark-color: #9C4A38;
$black-color: #000000;
$dark-color: #0F0F0F;
$main-color-op: rgba(253, 227, 167, .2);
$black-color-op68: rgba(0, 0, 0, .68);
$black-color-op55: rgba(0, 0, 0, .55);
$black-color-op5: rgba(0, 0, 0, .5);
$black-color-op4: rgba(0, 0, 0, .4);
$black-color-op3: rgba(0, 0, 0, .3);
$dark-color-op79: rgba(15, 15, 15, .79);
$dark-color-op6: rgba(15, 15, 15, .6);

// transition
$transition: all .3s ease-out;
