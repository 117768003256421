/* 4. Main */
.main {
    flex-grow: 1; }

/* 4.1 Main - Sections' scroll */
.section-scroll {
    position: fixed;
    z-index: 100;
    right: 90px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 540px;
    width: 3px;
    &-item {
        width: 3px;
        background: $main-color;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0;
            background: $mark-color;
            transition: all 1s cubic-bezier(.55,.085,0,.99); }
        &.active {
            &:after {
                height: 100%; } } } }

/* 4.2 Main - Sections general */
.section {
    background: $dark-color;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
        width: 3px; }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $main-color; }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $mark-color; }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $mark-color; }
    &-inner {
        min-height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-left: 80px;
        padding-right: 80px; }
    &-cnt {
        z-index: 1;
        opacity: 0;
        transition: all 1s cubic-bezier(.55,.085,0,.99);
        transition-delay: .1s;
        transform: translateY(80%); }
    &.active {
        .section-cnt {
            opacity: 1;
            transform: translateY(0); } } }

/* 4.3 Main - Hero section. First section */
.hero {
    display: flex;
    align-items: flex-end;
    padding-bottom: 140px;
    padding-top: 140px;
    padding-left: 100px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: $black-color-op4; }
    &-title {
        margin: 0 0 30px; }
    &-txt {
        font-family: $nm-regular;
        font-size: 25px;
        line-height: 130%;
        color: $main-color;
        margin: 0; }
    &-play {
        margin-bottom: 40px;
        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 105px;
            height: 105px;
            border-radius: 50%;
            background: $mark-color;
            transition: $transition;
            i {
                color: $main-color;
                transition: $transition;
                transition-delay: .15s; }
            &:hover {
                transform: scale(1.1);
                i {
                    transform: scale(1.3); } } } }
    &-inner {
        position: relative;
        overflow: hidden;
        &-cnt {
            height: 100vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            display: flex; }
        &__title {
            font-family: $nm-medium;
            font-size: 100px;
            line-height: 100%;
            text-align: center;
            color: $main-color;
            margin-bottom: 70px;
            &:last-child {
                margin-bottom: 0; } }
        &__txt {
            text-align: center;
            font-size: 15px;
            line-height: 150%;
            color: $main-color;
            padding: 0 60px; } }
    &-project {
        .hero-inner-cnt {
            align-items: center;
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: $black-color-op5; } }
        .container {
            position: relative;
            z-index: 100;
            padding-top: 213px; } }
    &-blog {
        &:after {
            background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 100%); }
        .hero-inner-cnt {
            align-items: flex-end;
            .container {
                padding-bottom: 250px;
                position: relative;
                z-index: 1; } }
        .hero-inner__title {
            text-align: left; } } }

/* 4.3.1 Main - Styles for magnific video */
.mfp-iframe-holder .mfp-content {
    max-width: calc(100% - 80px); }

/* 4.4 Main - Experience section. Second section */
.experience {
    padding: 120px 80px 100px;
    &-img {
        padding-left: 90px;
        padding-top: 75px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 35px;
            left: 0;
            background-image: url(../images/experience-bg.png);
            background-repeat: no-repeat; }
        img {
            position: relative;
            z-index: 1;
            max-width: 100%; } }
    &-info {
        padding-top: 175px;
        &__title {
            margin: 0 0 25px; }
        &__txt {
            font-family: $nm-regular;
            font-size: 15px;
            line-height: 130%;
            color: $main-color;
            width: 63%;
            margin-bottom: 110px; } }
    &-skills {
        max-width: 440px;
        width: 100%;
        &__item {
            margin-bottom: 52px; }
        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2px; }
        &__title {
            font-family: $nm-regular;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            text-transform: uppercase;
            color: $main-color;
            margin: 0; }
        &__percent {
            font-family: $nm-regular;
            font-size: 15px;
            line-height: 18px;
            text-transform: uppercase;
            color: $main-color;
            margin: 0; }
        progress {
            width: 100%;
            background-color: $main-color;
            height: 4px;
            &[value] {
                appearance: none;
                border: 0;
                background-color: $mark-color;
                &::-webkit-progress-bar {
                    border: 0;
                    background-color: $main-color; }
                &::-webkit-progress-value {
                    border: 0;
                    background-color: $mark-color; } } } } }

/* 4.5 Main - Education section. Third section */
.education {
    padding: 120px 80px 100px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: $black-color-op4; }
    &-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 97px; }
    &-title {
        margin: 0; }
    &-table {
        border-top: 1px solid $main-color;
        &__row {
            border-bottom: 1px solid $main-color;
            padding: 40px 0;
            align-items: center; }
        &__txt {
            font-family: $nm-regular;
            font-size: 28px;
            line-height: 100%;
            color: $main-color;
            margin: 0; }
        &__descr {
            font-family: $nm-regular;
            font-size: 15px;
            line-height: 130%;
            color: $main-color;
            margin: 0;
            padding-right: 3px; }
        &__cell {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0; } } }
    .swiper-buttons {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-button-prev, .swiper-button-next {
            position: relative;
            z-index: 10;
            left: inherit;
            right: inherit;
            margin: 0 20px;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center;
            &:after {
                content: none; }
            &:focus {
                outline: none; } }
        .swiper-button-prev {
            background-image: url(../images/prev.svg); }
        .swiper-button-next {
            background-image: url(../images/next.svg); } } }

/* 4.6 Main - Projects section. Fourth section */
.projects {
    &-list {
        overflow: inherit;
        .swiper {
            &-wrapper {
                transition-timing-function: linear!important; }
            &-slide {
                position: relative;
                width: auto;
                margin-right: 180px; } }
        &__item {
            padding: 0 93px;
            &:hover {
                cursor: url('../images/hover.svg'), pointer; } }
        &__txt {
            font-size: 60px;
            line-height: 100%;
            color: $main-color;
            white-space: nowrap;
            text-align: center; }
        &__hover {
            position: absolute;
            width: 500px;
            opacity: 0;
            margin: auto;
            z-index: 1;
            transform: translateY(-50%);
            transition: .2s ease; }
        &__img {
            position: relative;
            padding-bottom: 120%;
            display: block;
            cursor: url('../images/hover.svg'), pointer;
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%; } } } }

/* 4.7 Main - Services section. Fifth section */
.services {
    padding: 120px 80px 100px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: $dark-color-op6; }
    &-title {
        display: flex;
        align-items: flex-end;
        font-weight: normal;
        margin: 0;
        font-family: $nm-medium;
        span.mark {
            display: block;
            font-size: 220px;
            line-height: 90%;
            margin-right: 34px; }
        span.txt {
            font-family: $nm-medium;
            margin-bottom: 15px;
            font-size: 15px;
            line-height: 18px;
            text-transform: uppercase;
            color: $main-color; } }
    &-subtitle {
        font-weight: normal;
        font-family: $nm-medium;
        font-size: 32px;
        line-height: 38px;
        color: $main-color;
        padding-right: 50px;
        margin: 0 0 130px;
        span {
            color: $mark-color; } }
    &-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -56px; }
    &-item {
        width: 41%;
        margin-bottom: 56px;
        &__title {
            font-weight: normal;
            font-family: $nm-medium;
            font-size: 15px;
            line-height: 130%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $main-color;
            margin: 0 0 27px; }
        &__descr {
            font-family: $nm-regular;
            font-size: 15px;
            line-height: 130%;
            color: $main-color; } } }

/* 4.8 Main - Reviews section. Sixth section */
.reviews {
    padding: 120px 80px 100px;
    &-title {
        margin: 0;
        padding-right: 70px; }
    &-txt {
        font-family: $nm-regular;
        font-size: 15px;
        line-height: 130%;
        color: $main-color;
        margin: 0 0 50px; }
    &-author {
        font-family: $nm-medium;
        font-size: 25px;
        line-height: 130%;
        color: $main-color;
        margin: 0 0 10px; }
    &-post {
        font-family: $nm-regular;
        font-size: 15px;
        line-height: 130%;
        text-transform: uppercase;
        color: $mark-color; }
    &-cnt {
        display: flex;
        padding-top: 100px; }
    &-quote {
        width: 33%;
        text-align: center; }
    &-items {
        width: 66%; }
    .swiper-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 50px -20px 0;
        .swiper-button-prev, .swiper-button-next {
            position: relative;
            z-index: 10;
            left: inherit;
            right: inherit;
            margin: 0 20px;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center;
            &:after {
                content: none; }
            &:focus {
                outline: none; } }
        .swiper-button-prev {
            background-image: url(../images/prev.svg); }
        .swiper-button-next {
            background-image: url(../images/next.svg); } } }

/* 4.9 Main - Latest news section. Seventh section*/
.news {
    padding: 120px 80px 100px;
    &-title {
        margin: 0 0 97px; }
    &-link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        &:hover {
            .news-item__img {
                img {
                    transform: scale(1.2); } } } }
    &-item {
        &__img {
            margin-bottom: 30px;
            margin-right: 30px;
            overflow: hidden;
            img {
                max-width: 100%;
                transition: $transition; } }
        &__info {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            &-date, &-tag {
                margin: 0;
                font-family: $nm-regular;
                font-size: 15px;
                line-height: 130%; }
            &-date {
                margin-right: 5px;
                color: $mark-color; }
            &-tag {
                color: $main-color; } }
        &__title {
            font-family: $nm-medium;
            font-size: 32px;
            line-height: 130%;
            color: $main-color;
            padding-right: 20px; } } }

/* 4.10 Main - Contact section. Eight section*/
.contact {
    padding: 120px 80px 100px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: $black-color-op4; }
    &-cnt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%; }
    &-title {
        margin: 0 0 45px; }
    &-subtitle {
        margin: 0 0 10px;
        font-family: $nm-regular;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        color: #9C4A38; }
    &-descr {
        font-family: $nm-regular;
        font-size: 15px;
        line-height: 130%;
        color: #FDE3A7;
        width: 80%; }
    &-logos {
        margin-bottom: -50px;
        &__item {
            margin-bottom: 50px;
            img {
                max-width: 100%; } } }
    &-info {
        &__mail, &__phone {
            display: block;
            color: $main-color;
            transition: $transition;
            text-decoration: none;
            font-size: 28px;
            &:hover {
                color: $mark-color; } }
        &__mail {
            line-height: 100%;
            font-family: $nm-regular;
            margin-bottom: 32px; }
        &__phone {
            line-height: 90%;
            font-family: $sf-medium;
            margin-bottom: 75px; } }
    &-form {
        .form-group {
            position: relative;
            margin-bottom: 50px;
            .form-label {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                font-family: $nm-regular;
                font-size: 15px;
                line-height: 100%;
                letter-spacing: 0.075em;
                text-transform: uppercase;
                color: #FDE3A7;
                transition: transform 150ms ease-out, font-size 150ms ease-out; }
            &.focused .form-label {
                transform: translateY(-15px);
                font-size: .75em; } }
        .form-control {
            height: 35px;
            border: 0;
            background: transparent;
            border-bottom: 1px solid $main-color;
            width: 100%;
            border-radius: 0;
            -webkit-appearance: inherit;
            font-size: 15px;
            line-height: 100%;
            padding: 0;
            color: $main-color;
            font-family: $nm-regular;
            &:focus {
                outline: none; } }
        textarea.form-control {
            height: 90px; }
        .error {
            display: block;
            color: $mark-color;
            margin-top: 10px; } }
    &-btn {
        border: 0;
        cursor: pointer;
        -webkit-appearance: inherit;
        border-radius: 0; } }


/* 6. Modal */
.modal.show {
    display: block;
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $black-color-op5; }
.modal-dialog {
    max-width: 500px;
    margin: 100px auto;
    text-align: center; }
.modal-content {
    border: 0;
    background: $dark-color; }
.modal-header {
    border: 0;
    flex-direction: column;
    position: relative;
    color: $main-color;
    padding: 30px;
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer; }
    .modal-subtitle {
        color: $mark-color; } }


/* 7. Ticker */

.content-marquee {
    position: relative;

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;

    -webkit-animation: 14s marquee linear infinite;
    animation: 14s marquee linear infinite;
    white-space: nowrap;

    --speed: 10s; }

.content-marquee.has-stopable:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }


.content-marquee__original>*,
.content-marquee__copy>* {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex; }


.content-marquee__copy {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%; }


@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translateX(0) translateZ(0);
        transform: translateX(0) translateZ(0); }
    100% {
        -webkit-transform: translateX(-100%) translateZ(0);
        transform: translateX(-100%) translateZ(0); } }

@keyframes vlt_marquee {
    0% {
        -webkit-transform: translateX(0) translateZ(0);
        transform: translateX(0) translateZ(0); }
    100% {
        -webkit-transform: translateX(-100%) translateZ(0);
        transform: translateX(-100%) translateZ(0); } }
