/* Social media icons style */
.social {
    position: fixed;
    z-index: 100;
    left: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &-list {
        padding: 0;
        margin: 0;
        list-style: none; }
    &-item {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0; } }
    &-link {
        display: block;
        height: 18px;
        width: 18px;
        i {
            width: 100%; } } }

