/* 9. Blog */
.blog {
    padding: 185px 0 140px;
    border-bottom: 1px solid $main-color;
    &-date {
        margin-bottom: 45px;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        color: $mark-color; }
    &-title {
        margin-bottom: 45px;
        font-family: $nm-medium; }
    &-txt {
        font-size: 16px;
        line-height: 162%;
        color: $main-color;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0; } }
    &-block {
        margin-bottom: 120px;
        &:last-child {
            margin-bottom: 0; } }
    &-img {
        margin-bottom: 120px;
        img {
            width: 100%; } }
    &-figure {
        border: 1px solid $main-color;
        margin: 0 0 60px;
        padding: 40px 72px 20px 128px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 38px;
            height: 38px;
            background-image: url(../images/blog-quote.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 41px;
            left: 41px; } }
    &-blockquote {
        margin: 0;
        &__txt {
            font-size: 20px;
            line-height: 175%;
            color: $main-color;
            margin-bottom: 40px; } }
    &-figcaption {
        font-size: 16px;
        line-height: 26px;
        font-family: $g-semibold;
        color: $mark-color;
        cite {
            font-family: $g-regular;
            color: $main-color;
            font-style: normal; } }
    &-tag {
        margin-bottom: 35px;
        &__item {
            color: $main-color;
            margin-right: 6px;
            &:after {
                content: ','; }
            &:last-child {
                margin-right: 0;
                &:after {
                    content: none; } } }
        &__link {
            color: $main-color;
            transition: $transition;
            &:hover {
                color: $mark-color; } } }
    &-tag, &-share {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 26px;
        &__txt {
            margin-right: 6px;
            color: $main-color;
            font-family: $nm-medium; }
        &__list {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            list-style: none; } }
    &-share {
        &__item {
            margin-right: 20px;
            &:last-child {
                margin-right: 0; } }
        &__link {
            display: flex;
            align-items: center;
            svg {
                path {
                    transition: $transition; } }
            &:hover {
                svg {
                    path {
                        fill: $mark-color; } } } } } }
