/* 10. Comment */
.comment {
    padding: 135px 0 200px;
    &-title {
        margin-bottom: 85px; }
    &-form {
        &__title {
            font-family: $nm-medium;
            font-size: 28px;
            line-height: 34px;
            color: $main-color;
            margin-bottom: 75px; } }
    &-cnt {
        margin-bottom: 75px; }
    &-item {
        &__inner {
            display: flex;
            align-items: flex-start;
            margin-bottom: 90px;
            &:last-child {
                margin-bottom: 0; } }
        &__img {
            width: 80px;
            height: 80px;
            margin-right: 35px;
            img {
                border-radius: 50%;
                max-width: 100%;
                width: 100%; } }
        &__info {
            width: calc(100% - 115px); }
        &__header {
            font-size: 13px;
            line-height: 19px;
            text-transform: uppercase;
            color: $main-color;
            margin-bottom: 11px; }
        &__author {
            font-size: 16px;
            line-height: 19px;
            color: $mark-color;
            margin-right: 3px; }
        &__txt {
            font-size: 15px;
            line-height: 173%;
            color: $main-color;
            margin-bottom: 20px; }
        .comment-items {
            padding-left: 115px; }
        &__btn {
            padding: 5px 25px;
            display: inline-block;
            font-family: $nm-medium;
            font-size: 12px;
            line-height: 14px;
            text-transform: capitalize; } } }
